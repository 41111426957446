// MOST COMMONLY USED COLORS THROUGHOUT THEME
// =============================================================================

$colorBlack: #000;
$colorWhite: #fff;
$colorGrey: #ddd;
$colorText: #666;

// DEFAULT COLORS
$colorGreen: green;
$colorRed: #F2304F;
$colorOrange: orange;
$colorBlue: blue;

$colorLink: $colorRed;

$colorMeta: #000000;
$colorNav: #909090;


$colorTheme: #68635C;