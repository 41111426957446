%button-base {
  cursor: pointer;
  outline: none;
  appearance: none;
  display: block;
  width: $buttonSize;
  height: $buttonSize;
  background: $colorTheme;
  border: 0 none;
  border-radius: 50%;
  @include font-regular;
  font-size: rem(16px);
  box-shadow: 0 2px 4px 0 rgba($colorBlack ,.13);
}

%button {
  @extend %button-base;

  &, &:link, &:visited {
    color: $colorWhite;
  }

  &:hover, &:active {
    color: $colorWhite;
    background-color: darken($colorRed, 10);
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1);
  }
}
