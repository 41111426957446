
@keyframes fadein {
    0% {
        position: fixed;
        transform: translateY(200%);
    }
    100% {
        position: fixed;
        transform: translateY(0);
    }
}

@keyframes fadeout {
    0% {
        position: fixed;
        transform: translateY(0);
    }
    100% {
        position: fixed;
        transform: translateY(200%);
    }
}