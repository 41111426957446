body {
    min-width: 1280px;
}

main {
    height: 200vh;
    background: $colorWhite;
}

.dummy-content {
    width: 100%;
    height: 3645px;
    background: url(../images/dummy-content.jpg) no-repeat;
    background-size: 1280px 3645px;
    margin-top: 100px;
}