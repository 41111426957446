// BREAKPOINT VARIABLES
// =============================================================================

$tiny: 0;
$small: 480px;
$medium: 768px;
$large: 1025px;
$wide: 1280px;
$ultra: 1440px;
$max: 1680px;


// JAVASCRIPT BREAKPOINTS
// =============================================================================

html:before {
  content: "mobile";
  display: none; /* Prevent from displaying. */
}
@include breakpoint($medium) {
  html:before {
    content: "tablet";
  }
}
@include breakpoint($large) {
  html:before {
    content: "desktop";
  }
}
