// =============================================================================

$base-font-size:    16px;
$base-line-height:  28px;


// IMPORT FONTS
// =============================================================================

// Pluto Sans Light
// @font-face {
//   font-family:"PlutoSansLight";
//   src:url("../fonts/PlutoSansLight.eot?#iefix");
//   src:url("../fonts/PlutoSansLight.eot?#iefix") format("eot"),
// 	url("../fonts/PlutoSansLight.woff2") format("woff2"),
// 	url("../fonts/PlutoSansLight.woff") format("woff"),
// 	url("../fonts/PlutoSansLight.ttf") format("truetype");
// }

// Pluto Sans Regular
@font-face {
  font-family:"PlutoSansRegular";
  src:url("../fonts/PlutoSansRegular.eot?#iefix");
  src:url("../fonts/PlutoSansRegular.eot?#iefix") format("eot"),
	url("../fonts/PlutoSansRegular.woff2") format("woff2"),
	url("../fonts/PlutoSansRegular.woff") format("woff"),
	url("../fonts/PlutoSansRegular.ttf") format("truetype");
}

// Pluto Sans Medium
@font-face {
  font-family:"PlutoSansMedium";
  src:url("../fonts/PlutoSansMedium.eot?#iefix");
  src:url("../fonts/PlutoSansMedium.eot?#iefix") format("eot"),
	url("../fonts/PlutoSansMedium.woff2") format("woff2"),
	url("../fonts/PlutoSansMedium.woff") format("woff"),
	url("../fonts/PlutoSansMedium.ttf") format("truetype");
}

// Pluto Cond Regular Italic
// @font-face {
//   font-family:"PlutoCondRegular";
//   src:url("../fonts/PlutoCondRegular-Italic.eot?#iefix");
//   src:url("../fonts/PlutoCondRegular-Italic.eot?#iefix") format("eot"),
// 	url("../fonts/PlutoCondRegular-Italic.woff") format("woff"),
// 	url("../fonts/PlutoCondRegular-Italic.ttf") format("truetype");
// }


// FONTS
// =============================================================================

%font-regular {
  font-family: 'PlutoSansRegular', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
}

%font-bold {
  font-family: 'PlutoSansMedium', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
}

%text {
  font-size: 1.125rem;
  line-height: 1.85em;
  letter-spacing: .015em;
  color: $colorText;
  max-width: 800px;

  @include breakpoint($medium) {
    font-size: 1.2rem;
  }
}


// FONT MIXINS
// =============================================================================

@mixin font-regular {
  @extend %font-regular;
}

@mixin font-bold {
  @extend %font-bold;
}

@mixin h1 {
  font-size: rem(72px);
  line-height: 1.1em;
  letter-spacing: -.04em;

  @include breakpoint($medium) {
    font-size: rem(80px);
  }

  @include breakpoint($large) {
    font-size: rem(96px);
  }
}

@mixin h2 {
  font-size: rem(26px);
  line-height: 1.1em;
  letter-spacing: -.04em;

  @include breakpoint($medium) {
    font-size: rem(32px);
  }

  @include breakpoint($large) {
    font-size: rem(48px);
  }
}
