// $space-xxxs: .5rem; // 8px
// $space-xxs: 1rem; // 16px
// $space-xs: 1.5rem; // 24px
// $space-s: 2rem; // 32px
// $space-m: 4rem; // 64px
// $space-l: 5rem; // 80px
// $space-xl: 6rem; // 96px

$transition: 420ms cubic-bezier(.52,.01,.16,1);
$transition--fast: 240ms cubic-bezier(.52,.01,.16,1);
$transition--slow: 820ms cubic-bezier(.52,.01,.16,1);


$buttonSize: 136px;