/* FUNCTIONS TO USE
 * Usage I: e.g. font-size: rem(20px); //Generates rem value of normal px value
 * works with all elements (like font-size, width, padding, margin etc.)
 * =============================================================================
*/

@function rem($px, $ref: $base-font-size) {
  @return toRem(toPx($px) / toPx($ref));
}
@function em($px, $ref: $base-font-size) {
  @return toEm(toPx($px) / toPx($ref));
}

@function pxToEm($px, $ref: $base-font-size) {
  @return toEm(toPx($px) / toPx($ref));
}
@function pxToRem($px) {
  @return toRem(toPx($px) / toPx($base-font-size));
}

@function toEm($val) {
  @return ($val + 0em);
}
@function toRem($val) {
  @return ($val + 0rem);
}
@function toPx($val) {
  @return ($val + 0px);
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}


@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
