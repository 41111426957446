// BASE FONT
// =============================================================================

html {
  @include font-regular;
  font-size: 87.5%;
  -webkit-font-smoothing: antialiased;

  @include breakpoint($medium) {
    font-size: 16px;
  }
}

// HEADER STYLES
// =============================================================================
h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  @include font-bold;
  color: $colorBlack;
  line-height: 1.1em;
  margin-bottom: 1rem;
}

// TEXT STYLES
// =============================================================================
b, em, strong {
  @include font-bold;
}
