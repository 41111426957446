.hero {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 100px;
    background: black url(../images/hero.jpg) no-repeat center center;
    background-size: cover;
    padding-bottom: 40%;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba($colorBlack, .5);
    }

    .layout {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
    }

    &-title,
    &-type,
    &-type:after,
    &-nextbestclick {
        z-index: 3;
        position: absolute;
    }

    $offset: 200px;

    &-title {
        top: #{$offset + 100px};
        max-width: 700px;
        font-size: rem(32px);
        font-weight: normal;
        line-height: 1.3;
        color: $colorWhite;
    }

    &-type {
        top: $offset;
        font-size: rem(24px);
        color: $colorWhite;

        &:after {
            content: "";
            display: block;
            bottom: -20px;
            width: 48px;
            height: 2px;
            background: $colorWhite;
        }
    }
}

.nextbestclick {
    right: 2rem;
    bottom: #{$buttonSize / 2 * -1};
    transform: none;

    &--sticky {
        position: fixed;
        bottom: 2rem;
        transform: translateY(0);
        transition: $transition--fast;
    }

    &--hide-sticky {
        position: fixed;
        transform: translateY(#{$buttonSize * 2});

        .scrolled--up & {
            transition: $transition--fast;
        }
    }

    button {
        @extend %button;
        transition: $transition--fast;

        &:before {
            content: "";
            display: block;
            width: 32px;
            height: 32px;
            margin: 0 auto .5rem;
            background: url(../images/ticket.svg) no-repeat center center;
        }
    }
}