.header {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 1280px;
  background: $colorWhite;
  transition: $transition--fast;

  .scrolled & {
    transform: translateY(-48px);
    box-shadow: 0 2px 24px 0 rgba($colorBlack ,.1);
  }

  &-meta {
    display: flex;
    width: 100%;
  }

  &-meta__left {
    flex-basis: 50%;
    height: 48px;
    background: $colorMeta url(../images/meta_left.svg) no-repeat top left;
    background-size: 720px 48px;
  }

  &-meta__right {
    flex-basis: 50%;
    height: 48px;
    background: $colorMeta url(../images/meta_right.svg) no-repeat top right;
    background-size: 720px 48px;
  }

  &-main {
    width: 100%;
    padding: 2rem 0;

    .scrolled & {
      padding: 1rem 0;
    }

    .layout {
      display: flex;
      flex-direction: row;
    }
  }
}

.logo {
  display: flex;
  width: 114px;
  height: 87px;
  background: url(../images/logo.svg) no-repeat center center;
  background-size: 100% 100%;
  transition: $transition--fast;

  .scrolled & {
    width: 78px;
    height: 60px;
  }
}

.target-nav {
  z-index: -1;
  position: absolute;
  top: -72px;
  left: 226px;
  width: 367px;
  height: 125px;
  background: url(../images/target-nav.png) no-repeat top left;
  background-size: 367px 125px;
  transition: $transition--fast;

  .scrolled & {
    transform: translateY(-48px);
  }
}

.navigation {
  position: absolute;
  top: 42px;
  left: 280px;
  display: flex;
  align-self: center;

  .scrolled & {
    top: 21px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: rem(32);
      font-size: rem(17);
      color: $colorNav;
      @include font-bold;

      &.active {
        position: relative;
        color: $colorBlack;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: rem(-8px);
          width: 100%;
          height: rem(2px);
          background: $colorBlack;
        }
      }
    }
  }
}