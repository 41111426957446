// BORDER BOX
// =============================================================================
@include border-box-sizing;

// CONFIGURE SUSY 2 GLOBAL DEFAULTS
// =============================================================================

$susy: (
  columns: 12,
  gutters: 20px/40px,
  column-width: 40px,
  container: 1280px,
  // gutter-position: inside-static,
  // debug: (
  //   image: show-columns,
  //   color: rgba($colorRed,0.1),
  //   // output: overlay,
  //   // toggle: bottom right,
  // ),
);


// GENERAL LAYOUT
// =============================================================================
%layout {
  position: relative;
  padding: 0 2rem;
  margin: 0 auto;
  max-width: $wide;

  // @include breakpoint($medium) {
  //   padding: 0 4rem;
  // }
}

.layout {
  @extend %layout;
}
